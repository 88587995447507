import { format, parseISO } from "date-fns"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Blog({ pageContext: { data }, location }) {
  const post = data.frontmatter
  const coverImage = post?.cover_image?.childImageSharp?.fixed
  return (
    <Layout>
      <Seo
        title={post.title}
        description={post.excerpt}
        pathname={location.pathname}
        meta={[
          {
            property: "og:type",
            content: "article",
          },
        ].concat(
          coverImage
            ? [
                {
                  property: "og:image",
                  content: "https://www.tonywomersley.com" + coverImage.srcWebp,
                },
                {
                  property: "og:image:width",
                  content: coverImage.width,
                },
                {
                  property: "og:image:height",
                  content: coverImage.height,
                },
              ]
            : [
                {
                  property: "og:image",
                  content: "https://www.tonywomersley.com" + post?.cover_image,
                },
                {
                  property: "og:image:width",
                  content: 1200,
                },
                {
                  property: "og:image:height",
                  content: 600,
                },
              ]
        )}
      />
      <article className="border-b">
        <section className="max-w-7xl mx-auto w-11/12 py-6 md:py-12 2xl:w-full flex flex-col gap-10">
          {post?.cover_image?.childImageSharp ? (
            <GatsbyImage
              image={post.cover_image.childImageSharp.gatsbyImageData}
              className="h-[34rem] object-cover"
              alt={post.title}
            />
          ) : (
            <img
              src={post.cover_image}
              className="h-[34rem] object-cover"
              alt={post.title}
            />
          )}
          <h1 className="text-center font-serif max-w-4xl mx-auto text-2xl md:text-4xl">
            {post.title}
          </h1>
          <h2 className="text-center max-w-4xl mx-auto w-full">
            {post.excerpt}
          </h2>
          {post.publish_date && (
            <h3 className="text-center max-w-md mx-auto w-full text-sm fontlight italic text-stone-400">
              {format(parseISO(post.publish_date), "yyyy/MM/dd")}
            </h3>
          )}
        </section>
      </article>
      <Content data={data.html} />
    </Layout>
  )
}

const Content = ({ data }) => {
  return (
    <section
      className="max-w-4xl  w-11/12 2xl:w-full text-black mx-auto py-6 flex flex-col text-left prose md:prose-lg prose-h1:text-3xl prose-h1:font-serif"
      dangerouslySetInnerHTML={{ __html: data }}
    />
  )
}
